.rbc-month-row {
}

/* Ensure each event has enough height to be visible */
.rbc-header {
  text-transform: capitalize;
  padding: 10px 0;
  @apply text-gray-700
}

.rbc-month-view .rbc-event{
  background-color: transparent;
  @apply text-black
}

/*
.rbc-month-view  .rbc-today {
  @apply bg-goldlight
}
  */


  /* Force events to display in columns */
.rbc-day-slot .rbc-event {
  display: inline-block;
  position: absolute;
  width: 100%; /* Default: full width */
}

/* If multiple events are detected in a time slot, they will split the space */
.rbc-day-slot .rbc-event-multiple {
  width: 50%;  /* Adjust this to change how much space each event takes */
}

/* For more than 2 events, you can adjust */
.rbc-day-slot .rbc-event-multiple-3 {
  width: 33.33%;  /* Adjust the width to 33% if there are 3 events */
}

/* Further events, adjust accordingly */
.rbc-day-slot .rbc-event-multiple-4 {
  width: 25%;  /* Adjust the width to 25% if there are 4 events */
}

/* Add space between columns to avoid overlap */
.rbc-day-slot .rbc-event {
  margin-right: 5px;
}