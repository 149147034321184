/* src/styles/customCalendar.css */

.react-calendar {
  @apply bg-white rounded-lg shadow-md w-full;
}

.react-calendar__navigation {
  @apply flex justify-between p-2 bg-gray-100 rounded-t-lg;
}

.react-calendar__month-view__weekdays {
  @apply grid grid-cols-7 text-center bg-gray-200 py-2 rounded-lg;
}

/*
.react-calendar__month-view__days {
  @apply grid grid-cols-7 gap-1;
}
*/
.react-calendar__tile {
  @apply p-2 text-center text-black rounded-lg bg-gold ;
}

.react-calendar__tile--active {
  @apply bg-black text-gold !important;
}

.react-calendar__tile--now {
  @apply bg-black text-white;
}

.react-calendar__tile--range {
  @apply bg-gold;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  @apply bg-gold
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  @apply bg-black text-gold
}


.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  @apply bg-gold
}


.react-calendar__tile:hover {
  @apply bg-gold text-black
}

