.rbc-calendar {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
}

.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eaeaea;
}

.rbc-toolbar button {
  background-color: #6366f1;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 14px;
}

.rbc-day-bg {
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.rbc-today {
  background-color: rgba(99, 102, 241, 0.1); /* Tailwind blue-500 light */
}

.rbc-event {
  background-color: #6366f1;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-event:hover {
  background-color: #4f46e5;
}

.rbc-time-header {
  background-color: #f9f9f9;
  font-weight: 500;
}